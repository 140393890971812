import React, { useState, useEffect } from "react";
import { Prevention } from "../components/Prevention";
import { Stats } from "../components/Stats";
import { Historique } from "../components/Historique";
import { Introduction } from "../components/Introduction";

export const Eliza: React.FC = () => {
  const [language, setLanguage] = useState<"fr" | "en">("fr");

  useEffect(() => {
    const userLang = navigator.language;
    if (userLang !== "fr-FR" && userLang !== "fr") {
      setLanguage("en");
    }
  }, []);

  const translations = {
    fr: {
      modelResults:
        "Les résultats du modèle incluent l'ensemble des prédictions, mais il n'est pas recommandé de parier sur tous les matchs à long terme. L'évolution du capital présentée ci-dessous a été calculée en misant une petite fraction (1/50) de celui-ci sur chaque match, en ne sélectionnant que les matchs jugés intéressants et recommandés par Eliza et son logo.",
    },
    en: {
      modelResults:
        "The model results include all predictions, but it is not recommended to bet on all matches in the long term. The capital evolution presented below was calculated by betting a small fraction (1/50) of it on each match, selecting only the matches deemed interesting and recommended by Eliza and her logo.",
    },
  };

  const { modelResults } = translations[language];

  return (
    <main className="container mx-auto px-4">
      <div className="flex flex-col items-start w-full">
        <Introduction />
      </div>

      <p
        className="mt-22px"
        style={{
          fontSize: "20px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: "5px 10px",
          textAlign: "center",
          marginBottom: "-30px",
          marginTop: "40px",
          fontWeight: "bold",
        }}
      >
        {modelResults}
      </p>

      <div className="flex justify-center mt-20 w-full">
        <Stats />
      </div>
      <div className="mt-5">
      <img src={`/images/graph.png?timestamp=${new Date().getTime()}`} alt="Graph" />

      </div>

      <div className="flex flex-col items-start w-full">
        <Historique />
      </div>

      <div className="flex flex-col items-start w-full">
        <Prevention />
      </div>
    </main>
  );
};
